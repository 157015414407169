import {useEffect, useState} from 'react';
import Marquee from 'react-fast-marquee';
import TechCard from './tech-cards/TechCard';
import {TechCardExtended, TechFiltersEnum} from './tech-types';
import {TECH, TECH_FILTERS} from './tech-data';
import {ReactComponent as FilterSvg} from './media/filter.svg';
import {ReactComponent as ClearSvg} from './media/clear.svg';
import classNames from 'classnames';
import {useWindowDimensions} from '../../../../../utils/hooks/hooks';
import AnimatedButtonHOC from '../../../../misc/animated/button/AnimatedButtonHOC';
import './tech.css';

const TARGET_ITEMS_PER_BUCKET = 6;

const Tech = () => {
  const [techBuckets, setTechBuckets] = useState<
    Record<number, TechCardExtended[]>
  >({});

  const [filters, setFilters] = useState<Record<string, true>>({});

  const {width} = useWindowDimensions();

  useEffect(() => {
    let techItems = [...TECH];

    let buckets: Record<number, TechCardExtended[]> = {};
    const bucketsToCreate = Math.floor(
      techItems.length / TARGET_ITEMS_PER_BUCKET,
    );

    for (let index = 0; index < bucketsToCreate; index++) {
      buckets[index] = [];
    }

    while (techItems.length) {
      for (const bucketIndex in buckets) {
        if (!techItems.length) {
          break;
        }

        const randomIndex = Math.floor(Math.random() * techItems.length);
        const selectedTech = techItems[randomIndex];

        buckets = {
          ...buckets,
          [bucketIndex]: [...buckets[bucketIndex], selectedTech],
        };

        const leftTechItems = techItems.slice(0, randomIndex);
        const rightTechItems = techItems.slice(randomIndex + 1);

        techItems = leftTechItems.concat(rightTechItems);
      }
    }

    setTechBuckets(buckets);
  }, []);

  function handleFilters(filterId: TechFiltersEnum): void {
    const filterInFilters = filterId in filters;

    const filtersCopy = {...filters};
    if (filterInFilters) {
      delete filtersCopy[filterId];
    } else {
      filtersCopy[filterId] = true;
    }

    setFilters(filtersCopy);
  }

  function determineHighlighted(labels: string[]) {
    for (const label of labels) {
      if (label in filters) {
        return true;
      }
    }
    return false;
  }

  const showMarquee = width >= 800;

  return (
    <div className="tech-container">
      <div className="tech-main">
        <div className="tech-header">
          <h2>tech</h2>
        </div>
        <div className="tech-body">
          {showMarquee && (
            <div className="filters-container">
              <div className="filter-icons">
                <FilterSvg />
                <AnimatedButtonHOC>
                  <ClearSvg
                    className={classNames('clear-filters', {
                      filters: Object.entries(filters).length,
                    })}
                    onClick={() => setFilters({})}
                    role="button"
                  />
                </AnimatedButtonHOC>
              </div>
              <div
                className={classNames('tech-filters', {
                  'no-marquee': !showMarquee,
                })}
              >
                {TECH_FILTERS.map((filter) => (
                  <AnimatedButtonHOC
                    className={classNames('filter-chip box', {
                      selected: filter.id in filters,
                    })}
                    key={filter.id}
                    onClick={() => handleFilters(filter.id)}
                    role="button"
                  >
                    {filter.label}
                  </AnimatedButtonHOC>
                ))}
              </div>
            </div>
          )}
          <div className={classNames({'no-marquee': !showMarquee})}>
            {Object.entries(techBuckets).map(
              (
                [
                  // eslint-disable-next-line @typescript-eslint/no-unused-vars
                  _,
                  techBucket,
                ],
                index,
              ) => {
                const bucketIndexIsOdd = (index + 1) % 2;
                return showMarquee ? (
                  <Marquee
                    autoFill
                    direction={bucketIndexIsOdd ? undefined : 'right'}
                    gradient={false}
                    key={`marquee-${index}`}
                    // play={!Object.entries(filters).length}
                    speed={10}
                    style={{
                      padding: '25px 0',
                    }}
                  >
                    {techBucket.map((techItem) => (
                      <TechCard
                        highlighted={determineHighlighted(techItem.labels)}
                        key={techItem.text}
                        src={techItem.src}
                        text={techItem.text}
                      />
                    ))}
                  </Marquee>
                ) : (
                  techBucket.map((techItem) => (
                    <TechCard
                      highlighted={determineHighlighted(techItem.labels)}
                      key={techItem.text}
                      src={techItem.src}
                      text={techItem.text}
                    />
                  ))
                );
              },
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tech;
