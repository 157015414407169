import {TechCardProps} from './tech-cards/TechCard';

export enum TechFiltersEnum {
  LANGUAGE = 'language',
  FRONTEND = 'frontend',
  BACKEND = 'backend',
  FRAME_LIB = 'frame-lib',
  DEVOPS = 'devops',
}

export interface Filter {
  id: TechFiltersEnum;
  label: string;
}

export interface TechCardExtended extends TechCardProps {
  labels: TechFiltersEnum[];
}
