import {FC, ReactNode} from 'react';
import './timeline.css';

interface TimelineProps {
  children: ReactNode;
}

const Timeline: FC<TimelineProps> = (props) => {
  const {children} = props;
  return <div className="timeline-container">{children}</div>;
};

export default Timeline;
