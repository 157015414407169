import {FC, ReactNode} from 'react';
import classNames from 'classnames';
import './timeline-element.css';

export type TimelineElementPosition = 'left' | 'right';

export interface TimelineElementProps {
  children: ReactNode;
  position: TimelineElementPosition;
}

const TimelineElement: FC<TimelineElementProps> = (props) => {
  const {children, position} = props;
  return (
    <div
      className={classNames('timeline-element-container', {
        left: position === 'left',
        right: position === 'right',
      })}
    >
      <div className="element-wrapper">{children}</div>
    </div>
  );
};

export default TimelineElement;
