import {FC, ReactElement} from 'react';
import TimelineElement, {
  TimelineElementPosition,
} from '../timeline/timeline-element/TimelineElement';
import {Variants, motion} from 'framer-motion';
import './experience-card.css';

interface ExperienceCardProps {
  date: string;
  entity: string;
  position: TimelineElementPosition;
  svg: ReactElement;
  title: string;
}

const cardVariants: Variants = {
  offscreen: {
    opacity: 0,
    y: 75,
  },
  onscreen: {
    opacity: 1,
    y: 0,
    transition: {
      type: 'spring',
      bounce: 0.4,
      duration: 0.8,
    },
  },
};

const ExperienceCard: FC<ExperienceCardProps> = (props) => {
  const {date, entity, position, svg, title} = props;
  return (
    <TimelineElement position={position}>
      <motion.div
        initial="offscreen"
        whileInView="onscreen"
        variants={cardVariants}
        viewport={{once: true, amount: 0.8}}
      >
        <div className="experience-card">
          <div className="experience-card-image">{svg}</div>
          <h3 className="experience-card-header">
            {entity} <span>{date}</span>
          </h3>
          <div className="experience-card-description">{title}</div>
        </div>
      </motion.div>
    </TimelineElement>
  );
};

export default ExperienceCard;
