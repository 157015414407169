import {useEffect, useState} from 'react';
import classNames from 'classnames';
import TypewriterComponent from 'typewriter-effect';
import './terminal.css';

interface TerminalEntry {
  command: string;
  output?: string;
}

/**
 * @VisibleForTesting
 */
export const TerminalIO: TerminalEntry[] = [
  {command: './taylor.sh'},
  {command: 'echo $NAME', output: 'Taylor Fuller'},
  {command: 'echo $BIRTH_PLACE', output: 'Anchorage, AK'},
  {command: 'echo $CURRENT_LOCATION', output: 'Bend, OR'},
  {command: 'echo $EDUCATION', output: 'BS Mechanical Engineering'},
  {
    command: 'echo $HOBBIES',
    output: 'Software development, homelab, video games, camping, hiking',
  },
  {command: 'echo $CONTACT', output: 'inquiries@taylorfuller.dev'},
  {command: ''},
];

const Terminal = () => {
  const [terminalLines, setTerminalLines] = useState([TerminalIO[0]]);
  const [currentLine, setCurrentLine] = useState(0);

  useEffect(() => {
    if (!currentLine) {
      return;
    }
    if (currentLine === TerminalIO.length) {
      return;
    }
    setTerminalLines((previous) => [...previous, TerminalIO[currentLine]]);
  }, [currentLine]);

  return (
    <div className="terminal-container">
      <div className="terminal">
        <div className="terminal-header">
          <div className="terminal-buttons">
            <div className="terminal-button close" />
            <div className="terminal-button minimize" />
            <div className="terminal-button maximize" />
          </div>
        </div>
        <div className="terminal-body">
          {terminalLines.map((terminalLine, index) => {
            const lineIsCurrent = currentLine === index;
            const outputShouldShow = currentLine > index;
            const completed = index === TerminalIO.length - 1;
            return (
              <div
                className={classNames('terminal-entry', {
                  current: lineIsCurrent,
                })}
                key={`terminal-entry-${index}`}
              >
                <div className="terminal-entry-input-container">
                  <div className="terminal-entry-command">
                    {lineIsCurrent ? (
                      <TypewriterComponent
                        onInit={(typewriter) => {
                          typewriter
                            .pauseFor(150)
                            .start()
                            .typeString(terminalLine.command)
                            .pauseFor(75)
                            .callFunction(
                              () => !completed && setCurrentLine(index + 1),
                            );
                        }}
                        options={{
                          autoStart: true,
                          cursor: '',
                          delay: 5,
                        }}
                      />
                    ) : (
                      terminalLines[index].command
                    )}
                  </div>
                  {lineIsCurrent && <div className="cursor" />}
                </div>
                <div
                  className={classNames('terminal-entry-output', {
                    'has-output': !!terminalLine.output,
                  })}
                >
                  {outputShouldShow && terminalLine.output}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Terminal;
