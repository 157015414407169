import {Filter, TechCardExtended, TechFiltersEnum} from './tech-types';

export const TECH_FILTERS: Filter[] = [
  {
    id: TechFiltersEnum.LANGUAGE,
    label: 'Languages',
  },
  {
    id: TechFiltersEnum.FRONTEND,
    label: 'Frontend',
  },
  {
    id: TechFiltersEnum.BACKEND,
    label: 'Backend',
  },
  {
    id: TechFiltersEnum.FRAME_LIB,
    label: 'Frameworks & Libraries',
  },
  {
    id: TechFiltersEnum.DEVOPS,
    label: 'Devops',
  },
];

export const TECH: TechCardExtended[] = [
  {
    text: 'Bash',
    src: '/logos/tech/bash-logo.png',
    labels: [TechFiltersEnum.LANGUAGE, TechFiltersEnum.DEVOPS],
  },
  {
    text: 'Bootstrap',
    src: '/logos/tech/bootstrap-logo.png',
    labels: [TechFiltersEnum.FRONTEND, TechFiltersEnum.FRAME_LIB],
  },
  {
    text: 'CSS',
    src: '/logos/tech/css-logo.png',
    labels: [TechFiltersEnum.FRONTEND],
  },
  {
    text: 'Django',
    src: '/logos/tech/django-logo.png',
    labels: [TechFiltersEnum.BACKEND, TechFiltersEnum.FRAME_LIB],
  },
  {
    text: 'Docker',
    src: '/logos/tech/docker-logo.png',
    labels: [TechFiltersEnum.DEVOPS],
  },

  {
    text: 'ElectronJS',
    src: '/logos/tech/electron-logo.png',
    labels: [TechFiltersEnum.FRAME_LIB],
  },
  {
    text: 'GCP',
    src: '/logos/tech/gcp-logo.png',
    labels: [TechFiltersEnum.DEVOPS],
  },
  {
    text: 'Git',
    src: '/logos/tech/git-logo.png',
    labels: [TechFiltersEnum.DEVOPS],
  },
  {
    text: 'Github',
    src: '/logos/tech/github-logo.png',
    labels: [TechFiltersEnum.DEVOPS],
  },
  {
    text: 'HTML',
    src: '/logos/tech/html-logo.png',
    labels: [TechFiltersEnum.FRONTEND],
  },
  {
    text: 'JavaScript',
    src: '/logos/tech/javascript-logo.png',
    labels: [
      TechFiltersEnum.LANGUAGE,
      TechFiltersEnum.FRONTEND,
      TechFiltersEnum.BACKEND,
    ],
  },
  // {
  //   text: 'Jira',
  //   src: '/logos/tech/jira-logo.png',
  //   labels: [TechFiltersEnum.DEVOPS],
  // },
  {
    text: 'Kubernetes',
    src: '/logos/tech/kubernetes-logo.png',
    labels: [TechFiltersEnum.BACKEND, TechFiltersEnum.DEVOPS],
  },
  {
    text: 'MUI',
    src: '/logos/tech/mui-logo.png',
    labels: [TechFiltersEnum.FRONTEND, TechFiltersEnum.FRAME_LIB],
  },
  {
    text: 'Node',
    src: '/logos/tech/node-logo.png',
    labels: [TechFiltersEnum.BACKEND, TechFiltersEnum.FRAME_LIB],
  },
  {
    text: 'OpsGenie',
    src: '/logos/tech/opsgenie-logo.png',
    labels: [TechFiltersEnum.DEVOPS],
  },
  {
    text: 'Python',
    src: '/logos/tech/python-logo.png',
    labels: [TechFiltersEnum.LANGUAGE, TechFiltersEnum.BACKEND],
  },
  {
    text: 'React',
    src: '/logos/tech/react-logo.png',
    labels: [TechFiltersEnum.FRONTEND, TechFiltersEnum.FRAME_LIB],
  },
  {
    text: 'Redis',
    src: '/logos/tech/redis-logo.png',
    labels: [TechFiltersEnum.BACKEND, TechFiltersEnum.DEVOPS],
  },
  {
    text: 'Redux',
    src: '/logos/tech/redux-logo.png',
    labels: [TechFiltersEnum.FRONTEND, TechFiltersEnum.FRAME_LIB],
  },
  {
    text: 'Ruby',
    src: '/logos/tech/ruby-logo.png',
    labels: [TechFiltersEnum.LANGUAGE, TechFiltersEnum.BACKEND],
  },
  {
    text: 'Ruby on Rails',
    src: '/logos/tech/rails-logo.png',
    labels: [TechFiltersEnum.BACKEND, TechFiltersEnum.FRAME_LIB],
  },
  {
    text: 'SQL',
    src: '/logos/tech/sql-logo.png',
    labels: [TechFiltersEnum.LANGUAGE, TechFiltersEnum.BACKEND],
  },
  {
    text: 'TypeScript',
    src: '/logos/tech/typescript-logo.png',
    labels: [
      TechFiltersEnum.LANGUAGE,
      TechFiltersEnum.FRONTEND,
      TechFiltersEnum.BACKEND,
    ],
  },
];
