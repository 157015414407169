import {FC} from 'react';
import classNames from 'classnames';
import './tech-card.css';

export interface TechCardProps {
  highlighted?: boolean;
  src: string;
  text: string;
}

const TechCard: FC<TechCardProps> = (props) => {
  const {highlighted, src, text} = props;

  return (
    <div className={classNames('tech-card-container', {highlighted})}>
      <div className="icon-container">
        <img src={src} alt={`${text} logo`} />
      </div>
      <div className="card-body">{text}</div>
    </div>
  );
};

export default TechCard;
