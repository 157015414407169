import FullWidthBlock, {
  FullWidthBlockProps,
} from '../../layout/full-width-block/FullWidthBlock';
import Terminal from './components/terminal/Terminal';
import Skills from './components/tech/Tech';
import Experience from './components/experience/Experience';

const PAGE_SECTIONS: FullWidthBlockProps[] = [
  {
    component: <Terminal />,
    minHeight: 900,
  },
  {
    component: <Skills />,
    minHeight: 900,
  },
  {
    component: <Experience />,
    minHeight: 900,
    noMargin: true,
  },
];

const Home = () => {
  return (
    <div className="home-container">
      {PAGE_SECTIONS.map((section, index) => {
        const {component, minHeight, noMargin} = section;
        return (
          <FullWidthBlock
            component={component}
            key={`fwb-${index}`}
            minHeight={minHeight}
            noMargin={noMargin}
          />
        );
      })}
    </div>
  );
};

export default Home;
