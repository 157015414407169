import {FC, ReactElement} from 'react';
import {HTMLMotionProps, motion} from 'framer-motion';

interface AnimatedButtonHOCProps {
  children: ReactElement | string;
}

const AnimatedButtonHOC: FC<AnimatedButtonHOCProps & HTMLMotionProps<'div'>> = (
  props,
) => {
  const {children, ...rest} = props;

  return (
    <motion.div
      whileHover={{scale: 1.1}}
      whileTap={{scale: 0.9}}
      transition={{type: 'spring', stiffness: 400, damping: 17}}
      {...rest}
    >
      {children}
    </motion.div>
  );
};

export default AnimatedButtonHOC;
