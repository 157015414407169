import ExperienceCard from './experience-card/ExperienceCard';
import {ReactComponent as MablLogo} from './media/mabl-logo.svg';
import {ReactComponent as MsaLogo} from './media/msa-logo.svg';
import {ReactComponent as UaaLogo} from './media/uaa-logo.svg';
import Timeline from './timeline/Timeline';
import './experience.css';

const Experience = () => {
  return (
    <div className="experience-container">
      <div className="experience-header">
        <h2>experience</h2>
      </div>
      <div className="experience-body">
        <Timeline>
          <ExperienceCard
            entity="mabl"
            date="(1/2022 - present)"
            position="left"
            svg={<MablLogo width={100} />}
            title="Software Engineer, L2"
          />
          <ExperienceCard
            entity="Dev bootcamp &amp; self-study"
            date="(2/2021 - 12/2021)"
            position="right"
            svg={
              <div className="cursor-container">
                &gt;&nbsp;&nbsp;
                <div className="cursor" />
              </div>
            }
            title="Bootcamp, personal projects, additional courses"
          />
          <ExperienceCard
            entity="MSA Engineering Consultants"
            date="(4/2018 - 7/2020)"
            position="left"
            svg={<MsaLogo />}
            title="Mechanical Engineer"
          />
          <ExperienceCard
            entity="University of Alaska Anchorage"
            date="(2013 - 2017)"
            position="right"
            svg={<UaaLogo />}
            title="Bachelor of Science - Mechanical Engineering"
          />
        </Timeline>
      </div>
    </div>
  );
};

export default Experience;
