import {
  Navigate,
  RouteObject,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';
import {AppLayout} from './components/layout/app-layout/AppLayout';
import Home from './components/pages/home/Home';
import {homePath} from './utils/routes/utils/routes';

const ROUTES: RouteObject[] = [
  {
    path: '/',
    element: <AppLayout />,
    children: [
      {
        index: true,
        element: <Home />,
      },
    ],
  },
  {
    path: 'health',
    element: null,
  },
  {
    path: '*',
    element: <Navigate to={homePath} />,
  },
];

const router = createBrowserRouter(ROUTES);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
