import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App.tsx';
import {BrowserTracing, Replay, init} from '@sentry/react';
import './index.css';

init({
  enabled: process.env.NODE_ENV === 'production',
  dsn: 'https://79d678ca05a549219100d865e6712de4@o4505536773226496.ingest.sentry.io/4505536774537216',
  integrations: [
    new BrowserTracing({
      // set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ['https://taylorfuller.dev'],
    }),
    new Replay(),
  ],
  // performance monitoring
  tracesSampleRate: 0.5, // capture 100% of the transactions, reduce in production!
  // session replay
  replaysSessionSampleRate: 0.1, // this sets the sample rate at 10%, you may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // if you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
