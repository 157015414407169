import {FC} from 'react';
import './header-link.css';

interface HeaderLinkProps {
  href: string;
  imageAlt: string;
  imageSrc: string;
}

const HeaderLink: FC<HeaderLinkProps> = (props) => {
  const {href, imageAlt, imageSrc} = props;
  return (
    <a className="header-link" href={href} target="_blank">
      <img src={imageSrc} alt={imageAlt} />
    </a>
  );
};

export default HeaderLink;
