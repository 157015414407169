import {FC, ReactElement} from 'react';
import classNames from 'classnames';
import './full-width-block.css';

export interface FullWidthBlockProps {
  component: ReactElement;
  minHeight?: number;
  noMargin?: boolean;
}

const FullWidthBlock: FC<FullWidthBlockProps> = (props) => {
  const {component, minHeight = 400, noMargin} = props;
  return (
    <div
      className={classNames('full-width-block', {'no-margin': noMargin})}
      style={{minHeight}}
    >
      {component}
    </div>
  );
};

export default FullWidthBlock;
