import {Outlet} from 'react-router-dom';
import {useScrollToTop} from '../../../utils/routes/hooks/routes-hooks';
import Header from '../header/Header';
import {motion, useScroll} from 'framer-motion';
import './app-layout.css';

export const AppLayout = () => {
  useScrollToTop();
  const {scrollYProgress} = useScroll();

  return (
    <>
      <motion.div className="progress-bar" style={{scaleX: scrollYProgress}} />
      <div className="app-body">
        <Header />
        <Outlet />
      </div>
    </>
  );
};
