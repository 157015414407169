import HeaderLink from '../../misc/header-link/HeaderLink';
import {motion} from 'framer-motion';
import './header.css';

const Header = () => {
  return (
    <header className="app-header">
      <span className="name">taylor&#47;&#47;fuller</span>
      <div className="externals">
        <motion.div
          whileHover={{scale: 1.2}}
          whileTap={{scale: 0.9}}
          transition={{type: 'spring', stiffness: 400, damping: 17}}
        >
          <HeaderLink
            href="https://github.com/taylor-fuller"
            imageAlt="github logo"
            imageSrc="/icons/github-white.svg"
          />
        </motion.div>
      </div>
    </header>
  );
};

export default Header;
